exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blogs-js": () => import("./../../../src/pages/blogs.js" /* webpackChunkName: "component---src-pages-blogs-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-photography-js": () => import("./../../../src/pages/photography.js" /* webpackChunkName: "component---src-pages-photography-js" */),
  "component---src-pages-software-development-js": () => import("./../../../src/pages/software-development.js" /* webpackChunkName: "component---src-pages-software-development-js" */),
  "component---src-pages-ui-design-js": () => import("./../../../src/pages/ui-design.js" /* webpackChunkName: "component---src-pages-ui-design-js" */),
  "component---src-templates-blog-template-js-content-file-path-src-blogs-2023-001-ui-design-item-hover-index-mdx": () => import("./../../../src/templates/blog-template.js?__contentFilePath=/opt/buildhome/repo/src/blogs/2023/001ui-design-item-hover/index.mdx" /* webpackChunkName: "component---src-templates-blog-template-js-content-file-path-src-blogs-2023-001-ui-design-item-hover-index-mdx" */)
}

